import React, { useEffect, useState } from 'react';
import "../css/audition.css";
import AuditionBanner from "../images/auditionbanner2.png";

// markup
const AuditionPage = () => {
    const [youtubeID] = useState('FgQlLyhQ4fQ')
    useEffect(() => {

    }, []);
    return (
        <div className="audition_wrapper" id="audition">
            <img className="auditionbanner" src={AuditionBanner} alt=""></img>
            {/* <h1>募集は終了しました。たくさんのご応募ありがとうございました。</h1> */}
            {/* <p>1次選考通過者の皆様には、順次ご連絡をお送りいたします。しばらくお待ちください。</p> */}
            <br></br>
            <h1>応募条件</h1>
            <ul>
            <li>満18歳以上の女性</li>
            <li>週に4回以上の配信ができる方</li>
            <li>1年以上活動を継続できる方</li>
            <li>配信に必要な機材を所持または準備できる方</li>
            <li>心身ともに健康な方</li>
            <li>当グループのメンバーと仲良くできる方</li>
            <li>デビュー時に当グループと専属契約を結べる方</li>
            </ul>
            <br></br>
            <h1>歓迎要項</h1>
            <ul>
                <li>歌に自信のある方</li>
                <li>トーク力に自信のある方</li>
                <li>ゲームスキルに自信のある方</li>
                <li>配信者やVtuberとしての活動経験がある方</li>
                <li>英語や中国語などの多言語話者</li>
            </ul>
            <br></br>
            <h1>応募フォームは<a target="_blank" href="https://forms.gle/ADvJaX959CspN39B6">こちら</a></h1>
            <p>応募条件をご確認の上ご応募ください。</p>
            <iframe className='audition_video' frameborder="0" rel="0" modestbranding
                    title='Youtube player'
                    sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                    src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
            </iframe>
        </div>
    )
}

export default AuditionPage
